// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    projectId: 'glissandco-a5fd4',
    appId: '1:727150638057:web:01976cf9b3a900ebbbd178',
    storageBucket: 'glissandco-a5fd4.appspot.com',
    locationId: 'europe-west',
    apiKey: 'AIzaSyCFiiBliE9t_tg0E6Lp7ed2NamgiUO3Alg',
    authDomain: 'glissandco.fr',
    messagingSenderId: '727150638057',
    measurementId: "G-7XK1VX53J5",
  }
};

//GLISSANDCO
/*const firebaseConfig = {
  apiKey: "AIzaSyCFiiBliE9t_tg0E6Lp7ed2NamgiUO3Alg",
  authDomain: "glissandco-a5fd4.firebaseapp.com",
  projectId: "glissandco-a5fd4",
  storageBucket: "glissandco-a5fd4.appspot.com",
  messagingSenderId: "727150638057",
  appId: "1:727150638057:web:01976cf9b3a900ebbbd178",
  measurementId: "G-7XK1VX53J5"
};
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
