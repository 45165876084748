import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-vtsolution-project',
    templateUrl: './vtsolution-project.component.html',
    styleUrls: ['./vtsolution-project.component.scss']
})

export class VTSolutionProjectComponent implements OnInit {

  displayName:string
  email:string
  photoURL:any
  emailVerified:boolean
  isProjects:boolean
  countProjects:number = 0
  projects:any = []
  isLoading:boolean

  focus: any;
  focus1: any;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void { 
    this.isLoading = true
    this.getUser()
   }



  getUser() {
    const user = JSON.parse(localStorage.getItem('user'));
    
      if (user !== null) {
        // The user object has basic properties such as display name, email, etc.
        this.displayName = user.displayName;
        this.email = user.email;
        this.photoURL = user.photoURL;
        this.emailVerified = user.emailVerified;
        // The user's ID, unique to the Firebase project. Do NOT use
        // this value to authenticate with your backend server, if
        // you have one. Use User.getToken() instead.
        const uid = user.uid;
        this.getProjects(uid)
      }
  }


  getProjects(uid:string) {
    this.authService.getUserProjects(uid).then(result => {
      if(result.empty) { this.isProjects = false } else { this.isProjects = true }
      result.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        this.countProjects = this.countProjects + 1
        //console.log(doc.id, " => ", doc.data());
        this.projects.push(doc.data())
      });
      this.isLoading = false
    }).catch(error => {
        //console.log(error)
        this.isLoading = false
    })
  }

  
  createProject() {
    this.router.navigate(['/configurateur'])
  }

}
