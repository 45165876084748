import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

    displayName:string
    email:string
    photoURL:any
    emailVerified:boolean
    isProjects:boolean
    countProjects:number = 0
    projects:any = []
    isLoading:boolean
    userID:string;
    Price:number;
    closeResult: string;
    timerInterval:any

    packagesArray = [
      {
        'id':'miseenservice', 'name': 'Mise en service', 'price' : 1200, 'price2': 300, 'limit' : 1, quantity : 1, 'change': false, 'required' : true,
      },
      /*{
       'id':'tabletAndroid', 'name': 'Tablette Android pré-configurée', 'price' : 80, 'limit' : 10, quantity : 4, 'change': true, 'required' : false,
      },*/
      {
        'id':'customApp', 'name': 'Applications personnalisées (marque blanche)', 'price' : 2500, 'limit' : 1, quantity : 1, 'change': false, 'required' : false,
      },
      {
        'id':'pointenter', 'name': 'Tablette  pré-installée - Poste d\'encaissement', 'price' : 150, 'limit' : 5, quantity : 0, 'change': true, 'required' : false,
      },
      {
        'id':'pointvente', 'name': 'Tablette  pré-installée - Poste de contrôle', 'price' : 80, 'limit' : 5, quantity : 0, 'change': true, 'required' : false,
      },
      {
        'id':'deployApp', 'name': 'Déploiement de la solution', 'price' : 2400, 'price2':500, 'limit' : 1, quantity : 1, 'change': false, 'required' : true,
       },
       {
        'id':'network','name': 'Installation internet', 'price' : 199, 'limit' : 1, quantity : 1, 'change': false, 'required' : false,
       },
      {
        'id':'maintenance', 'name': 'Maintenance & Hotline', 'price' : 1400, 'price2': 400, 'limit' : 1, quantity : 1, 'change': false, 'required' : true,
      }
    ]

    constructor(private authService: AuthService, private router: Router, private modalService: NgbModal) { }



    open(content, type, modalDimension) {
      if (modalDimension === 'lg' && type === 'modal_mini') {
          this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
      } else if (modalDimension === '' && type === 'Notification') {
        this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
          this.modalService.open(content,{ centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
      }
  }


  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
    } else {
        return  `with: ${reason}`;
    }
  }

    increase_quantity(temp_package){
      if(temp_package.limit == temp_package.quantity){
        return alert("Can't add more")
      }else{
        temp_package.quantity++
        this.Price += temp_package.price
      }
      //this.countPrice()
    }
  
    decrease_quantity(temp_package){
        if(temp_package.quantity == 0){
          return alert("can't be in minus")
        }
        temp_package.quantity--
        this.Price -= temp_package.price
        //this.countPrice()
    }
    countPrice(project:any){
       this.Price = 0;
       var newData = []
        for(let p of this.packagesArray){

          //Required services
          if(p.required) {
            newData.push({name:p.name, price: project.dateType ? p.price2:p.price, qty:1})
          }

          //Custom APP
          if(p.id === 'customApp' && project.app) {
            this.Price += p.price*1
            newData.push({name:p.name, price: p.price, qty:1})
          }
          //Custom APP
          /*if(p.id === 'deployApp') {
            this.Price += p.price*1
            newData.push({name:p.name, price: p.price, qty:1})
          }*/
          //Network Install
          if(p.id === 'network' && project.internet) {
            this.Price += p.price*1
            newData.push({name:p.name, price: p.price, qty:1})
          }
          //Devices poste Enter
          if(p.id === 'pointenter' && project.devices) {
            this.Price += p.price*(Number(project.postes))
            newData.push({name:p.name, price: p.price*(Number(project.postes)), qty:(Number(project.postes))})
          }
           //Devices poste Controle
           if(p.id === 'pointvente' && project.devices) {
            this.Price += p.price*(Number(project.pointdevente))
            newData.push({name:p.name, price: p.price*(Number(project.pointdevente)), qty:(Number(project.pointdevente))})
          }
         
        }

        return { count: this.Price, datas:newData}
    }

    openLg(content) {
      this.modalService.open(content, { size: 'lg' });
    }

    ngOnInit() { 
        this.isLoading = true
        this.getUser()
        this.Price = 0
        //this.countPrice()
       }

      logout() {
        this.authService
          .logout()
          .then(truc => {
            Swal.fire({
              title: 'Déconnexion en cours...',
              html: 'Vous allez être déconnecté de votre compte',
              timer: 2000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading()
              },
              willClose: () => {
                clearInterval(this.timerInterval)
              }
            }).then((result) => {
              /* Read more about handling dismissals below */
              if (result.dismiss === Swal.DismissReason.timer) {
              //  console.log('I was closed by the timer')
    
                this.router.navigate(['/login'])
    
              }
            })
          })
             
          .catch((e) => console.log('error'/*e.message*/));
      }
    
      getUser() {
        const user = JSON.parse(localStorage.getItem('user'));
      //  console.log('local storage user info', user)
          if (user !== null) {
            // The user object has basic properties such as display name, email, etc.
            this.displayName = user.displayName || user.name;
            this.email = user.email;
            this.photoURL = user.photoURL;
            this.emailVerified = user.emailVerified;
            // The user's ID, unique to the Firebase project. Do NOT use
            // this value to authenticate with your backend server, if
            // you have one. Use User.getToken() instead.
            const uid = user.uid;
            this.userID = uid;
            this.getProjects(uid)
          }
      }
    
    
      getProjects(uid:string) {
        this.authService.getUserProjects(uid).then(result => {
          if(result.empty) { this.isProjects = false; this.isLoading = false; return } else { this.isProjects = true }
          result.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            this.countProjects = this.countProjects + 1
           // console.log(doc.id, " => ", doc.data());
            this.projects.push(doc.data())
          });
          this.isLoading = false
        }).catch(error => {
           // console.log('Pas de projet')
           // console.log(error)
            this.isLoading = false
        })
      }
    
      
      createProject() {
        this.router.navigate(['/vtsolution-project'])
      }

      verifyEmail() {
        this.authService.verifyEmail();
      }

}
