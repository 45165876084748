
import {
  Auth,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
} from '@angular/fire/auth';
import { doc, getDoc, getDocs, setDoc, updateDoc, addDoc, collection, query, getFirestore, where } from "@angular/fire/firestore";
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, updateProfile, sendEmailVerification, sendPasswordResetEmail, onAuthStateChanged } from "@angular/fire/auth";
import { Injectable } from '@angular/core';
import { LoginData } from 'src/app/core/interfaces/login-data.interface';



@Injectable({
  providedIn: 'root'
})
export class AuthService {

  
  constructor(private auth: Auth) {
    const authInit = getAuth();
    onAuthStateChanged(authInit, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        const uid = user.uid;
        localStorage.setItem('user', JSON.stringify(user));
        JSON.parse(localStorage.getItem('user')!);
      } else {
        // User is signed out
        localStorage.setItem('user', 'null');
      }
    })
  }


  login({ email, password }: LoginData) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }

  register({ email, password }: LoginData) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  loginWithGoogle() {
    return signInWithPopup(this.auth, new GoogleAuthProvider());
  }

  loginWithPhone(phoneNumber:any, appVerifier:any) {
    const auth = getAuth();
    return signInWithPhoneNumber(auth, phoneNumber, appVerifier)
  }

  getUser():any {
    const authInit = getAuth();
    const user = authInit.currentUser;
    return user
  }

  async checkIfUserExist(uid:string) {
    const db = getFirestore();
    const docRef = doc(db, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      var infoUser = docSnap.data()
      infoUser['uid'] = docSnap.id
      localStorage.setItem('user', JSON.stringify(infoUser));
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
      const newUser = await this.createNewUser(uid).then(resultNewUser => {
      console.log("New document created for user", resultNewUser);
      return newUser;
     })
      return null;
    }
  }

  async createNewUser(uid:string) {
    this.verifyEmail()
    const db = getFirestore();
    const authInit = getAuth();
    const user = authInit.currentUser;
    // Add a new document in collection "users"
    await setDoc(doc(db, "users", uid), {
      name: user?.displayName,
      email: user?.email,
      emailVerified: user?.emailVerified,
      phoneNumber: user?.phoneNumber,
      photoURL: user?.photoURL,
      providerId: user?.providerData[0]['providerId'],
      role:'user'
    });
    if(user?.providerData[0]['providerId'] === 'password') {
      return this.updateUser(localStorage.getItem('displayName'), uid)
    }
    return this.checkIfUserExist(uid)
  }


  updateUser(signupDisplayName:string, uid:string) {
    const auth = getAuth();
    const db = getFirestore();
    updateProfile(auth.currentUser, {
      displayName: signupDisplayName, photoURL: "https://ui-avatars.com/api/?name="+signupDisplayName+""
    }).then(() => {
      updateDoc(doc(db, "users", uid), {
        name: signupDisplayName,
        photoURL: "https://ui-avatars.com/api/?name="+signupDisplayName+"",
      });
      return this.checkIfUserExist(uid)
    }).catch((error) => {
      // An error occurred
      // ...
      return this.checkIfUserExist(uid)
    });
  }


  verifyEmail() {
    const auth = getAuth();
    sendEmailVerification(auth.currentUser)
      .then(() => {
        // Email verification sent!
        // ...
      }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  }


  sendPasswordResetEmail(email:string) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
      });
  } 



  async createNewProject(data:any) {
    const db = getFirestore();
    const authInit = getAuth();
    const user = authInit.currentUser;
    // Add a new document in collection "projects"
    await addDoc(collection(db, "projects"), {
      firstname: data?.firstname,
      lastname: data?.lastname,
      address: data?.address,
      postes: data?.postes,
      pointdevente: data?.pointdevente,
      internet: data?.internet,
      devices: data?.devices,
      description: data?.description,
      fromDate: data?.fromDate,
      dateType: data?.dateType,
      projectType: data?.projectType,
      toDate: data?.toDate,
      app: data?.app,
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
      uid: user?.uid,
      status:'etude',
    });
  }

  async getUserProjects(uid:string) {
    const db = getFirestore();
    const docRef = query(collection(db, "projects"), where("uid", "==", uid));
    const docSnap = await getDocs(docRef);
    return docSnap
  }

  async createNewEMail(data:any) {
    const db = getFirestore();
    const authInit = getAuth();
    const user = authInit.currentUser;
    // Add a new document in collection "projects"
    await addDoc(collection(db, "emails"), {
      displayName: data?.displayName,
      email: data?.email,
      phone: data?.phone,
      message: data?.message,
    });
  }

  logout() {
    return signOut(this.auth);
  }
  
   // Returns true when user is looged in and email is verified
   get isLoggedIn(): boolean {
    const user = localStorage.getItem('user');
    return user !== 'null' ? true : false;
    /*const authInit = getAuth();
    const user = authInit.currentUser;
    if(user === null) {
      return false;
    }
    return true*/
  }
  
}