import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { VTSolutionComponent } from './vtsolution/vtsolution.component';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { VTSolutionProjectComponent } from './vtsolution-project/vtsolution-project.component';
import { PatinoiresMobilesComponent } from './patinoires-mobiles/patinoires-mobiles.component';
import { LoginComponent } from './login/login.component';
import { ConfigurationComponent } from './configuration/configuration.component';
import { HomeComponent } from './home/home.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';
import { AboutComponent } from './about/about.component';
import { RollerComponent } from './roller/roller.component';

// Import canActivate guards
import { AuthGuard } from './shared/auth.guard';
import { SecureInnerPagesGuard } from './shared/secure-inner-pages.guard';

/*import {
  AuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';*/

//const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
//const redirectLoggedInToHome = () => redirectLoggedInTo(['vtsolution-project']);

const routes: Routes =[
    { path: 'vtsolution',             component: VTSolutionComponent },
    { path: 'user-profile',     component: ProfileComponent, canActivate: [AuthGuard],/*, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }*/ },
    { path: 'login',           component: LoginComponent, canActivate: [SecureInnerPagesGuard],},
    { path: 'register',           component: SignupComponent, canActivate: [SecureInnerPagesGuard] },
    { path: 'vtsolution-project',          component: VTSolutionProjectComponent/*, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }*/ },
    { path: 'mentions-legales',          component: MentionsLegalesComponent/*, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }*/ },
    { path: 'about',          component: AboutComponent/*, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }*/ },
    { path: 'home',          component: HomeComponent/*, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }*/ },
    { path: 'patinoires-mobiles',          component: PatinoiresMobilesComponent/*, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }*/ },
    { path: 'roller',          component: RollerComponent/*, canActivate: [AuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }*/ },
    { path: 'configuration',          component: ConfigurationComponent, canActivate: [AuthGuard], },
    { path: 'configuration/:id',          component: ConfigurationComponent, canActivate: [AuthGuard], },
    { path: '', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
