import { Component, OnInit } from '@angular/core';
import { AuthService } from '../core/services/auth.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-roller',
  templateUrl: './roller.component.html',
  styleUrls: ['./roller.component.css']
})
export class RollerComponent implements OnInit {

  displayName:any
  email:any
  phone:any
  message:any

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  sendEmail() {
    this.authService.createNewEMail(
      {
        displayName:this.displayName,
        email:this.email,
        phone:this.phone,
        message:this.message
      }
    )
    .then((result) => {
    console.log('SEND MAIL', result)
      
    Swal.fire({
      title: 'Merci !',
      text: 'Votre message a bien été envoyé !',
      icon: 'success',
      confirmButtonText: 'OK'
    })
   
    });
  }

}
