import { Component, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2'
import { AuthService } from '../core/services/auth.service';
import { Router } from '@angular/router';
import { GoogleMapsService } from '../core/services/google-maps.service';
import { ActivatedRoute } from '@angular/router';

declare var google: any;

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})


export class ConfigurationComponent implements OnInit {

  @ViewChild('addressText') addressText!: ElementRef;

  name:string;
  firstname:string;
  lastname:string;
  phone:number;
  email:string;
  postes:number;
  pointdevente:number;
  description:string = 'Pas de Message.';
  app:boolean;
  devices:boolean = true;
  fromDate: NgbDate;
  toDate: NgbDate;
  hoveredDate: NgbDate;
  closeResult: string;
  model1: NgbDate;
  model2: NgbDate;
  datetype: boolean;
  internet:boolean;
  placeSubscription:any;
  address:string;
  idConfig:any;

  focus;
  focus1;
  focus2;
  focus3;
  focus4;

  constructor(
    private modalService: NgbModal, 
    calendar: NgbCalendar, 
    private authService: AuthService, 
    private router: Router, 
    private googleMapsService: GoogleMapsService,
    private route: ActivatedRoute
    ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
    this.postes = 1;
    this.pointdevente = 1;
    this.datetype = false;
    this.app = false;
    this.internet = true;
    this.devices = true;
  }

  ngAfterViewInit(): void {
    this.googleMapsService.getPlaceAutocomplete(this.addressText);
  }

  onAddressChange(): void {
    this.placeSubscription =
    this.googleMapsService.placeObservable.subscribe(
      (place) => { 
        /*console.log('new place : ' +
      place.formatted_address);*/

    this.address = place.formatted_address }
    );
  }

  open(content, type, modalDimension) {
      if (modalDimension === 'sm' && type === 'modal_mini') {
          this.modalService.open(content, { windowClass: 'modal-mini', size: 'sm', centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
      } else if (modalDimension === '' && type === 'Notification') {
        this.modalService.open(content, { windowClass: 'modal-danger', centered: true }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
      } else {
          this.modalService.open(content,{ centered: true }).result.then((result) => {
              this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
      }
  }

  private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
          return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
          return 'by clicking on a backdrop';
      } else {
          return  `with: ${reason}`;
      }
  }
  isRangeStart(date: NgbDate){
    return this.model1 && this.model2 && date.equals(this.model1);
  }
  isRangeEnd(date: NgbDate){
    return this.model1 && this.model2 && date.equals(this.model2);
  }
  isInRange(date: NgbDate){
    return date.after(this.model1) && date.before(this.model2);
  }
  isActive(date: NgbDate){
    return date.equals(this.model1) || date.equals(this.model2);
  }
  endDateChanged(date){
    if (this.model1 && this.model2 && (this.model1.year > this.model2.year || this.model1.year === this.model2.year && this.model1.month > this.model2.month || this.model1.year === this.model2.year && this.model1.month === this.model2.month && this.model1.day > this.model2.day )) {
      this.model1 = this.model2;
    }
  }
  startDateChanged(date){
    if (this.model1 && this.model2 && (this.model1.year > this.model2.year || this.model1.year === this.model2.year && this.model1.month > this.model2.month || this.model1.year === this.model2.year && this.model1.month === this.model2.month && this.model1.day > this.model2.day )) {
      this.model2 = this.model1;
    }
  }
  
  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.idConfig = id
  }

  getTimestampDate(date:any) {
    var datum = Date.parse(date);
    return datum/1000;
  }

  saveProject() {
    if(this.datetype) {
      var fromDate = this.getTimestampDate(this.model1.month + '/' + this.model1.day + '/' + this.model1.year);
      var toDate = this.getTimestampDate(this.model2.month + '/' + this.model2.day + '/' + this.model2.year);
    } else {
      var fromDate = 0
      var toDate = 0
    }
    var result = {
      name:this.name,
      firstname: this.firstname,
      lastname: this.lastname,
      phone:this.phone,
      email:this.email,
      postes:this.postes,
      pointdevente:this.pointdevente,
      description:this.description,
      app:this.app,
      internet: this.internet,
      devices:this.devices,
      dateType: this.datetype,
      projectType: this.idConfig,
      fromDate: fromDate,
      toDate: toDate,
      address: this.address
    }
      //console.log(result)
      this.checkBeforeValid(result)
  }


  checkBeforeValid(data:any) {
    Swal.fire({
      title: 'Valider mon projet',
      html: '<b>Nom de famille:</b> '+data.firstname+'<br><b>Prénom:</b> '+data.lastname+'<br><b>Société:</b> '+data.name+'<br><b>Email:</b> '+data.email+'<br><b>Téléphone:</b> '+data.phone+'<br><br>Lieu du projet<br><b>'+data.address+'</b><br><br><b>Nbre de poste:</b> '+data.postes+'<br><b>Installation internet:</b> '+data.internet+'',
      showCancelButton: true,
      confirmButtonText: 'VALIDER',
      showLoaderOnConfirm: true,
      preConfirm: (phoneNumber) => {
          
        this.authService.createNewProject(data)
        .then((result) => {
          //console.log('CREATE PROJECT', result)
          localStorage.setItem('lastProject', JSON.stringify(data));
        });

      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/user-profile'])
        Swal.fire(
          'Terminé!',
          'Votre projet est enregistré.',
          'success'
        ).then((result) => {
          //this.router.navigate(['/user-profile'])
        });
      }
    })
  }


}
