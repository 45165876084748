import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app.routing';

import { AppComponent } from './app.component';
import { SignupComponent } from './signup/signup.component';
import { VTSolutionProjectComponent } from './vtsolution-project/vtsolution-project.component';
import { ProfileComponent } from './profile/profile.component';
import { VTSolutionComponent } from './vtsolution/vtsolution.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { environment } from '../environments/environment';
import { ConfigurationComponent } from './configuration/configuration.component';
import { PatinoiresMobilesComponent } from './patinoires-mobiles/patinoires-mobiles.component';
import { HomeComponent } from './home/home.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';

import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import { provideFirestore,getFirestore } from '@angular/fire/firestore';
import { RollerComponent } from './roller/roller.component';
import { AboutComponent } from './about/about.component';




@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    VTSolutionProjectComponent,
    ProfileComponent,
    VTSolutionComponent,
    NavbarComponent,
    FooterComponent,
    LoginComponent,
    ConfigurationComponent,
    PatinoiresMobilesComponent,
    HomeComponent,
    MentionsLegalesComponent,
    RollerComponent,
    AboutComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AppRoutingModule,

    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideDatabase(() => getDatabase()),
    provideFirestore(() => getFirestore())
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
