import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { getAuth, RecaptchaVerifier } from "@angular/fire/auth";
import Swal from 'sweetalert2'
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { LoginData } from '../core/interfaces/login-data.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Output() formData: EventEmitter<{
    email: string;
    password: string;
  }> = new EventEmitter();

  form: FormGroup;

  focus;
  focus1;

  recaptchaVerifier:any
  confirmationResult:any
  timerInterval:any

constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
      const auth = getAuth();
      this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    'size': 'normal',
    'callback': (response:any) => {
      //console.log(response)
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      // ...
    },
    'expired-callback': () => {
      // Response expired. Ask user to solve reCAPTCHA again.
      // ...
        }
      }, auth);

      this.form = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
      });

  }

  

  login() {
    this.authService
      .login(this.form.value)
      .then((datas) => this.analyseLogin(datas))
      .catch((e) => this.errorLogin(e));
  }

  loginWithGoogle() {
    this.authService
      .loginWithGoogle()
      .then((datas) => this.analyseLogin(datas))
      .catch((e) => this.errorLogin(e));
  }

  enterLoginPhone() {
    Swal.fire({
      title: 'Entrez votre numéro de téléphone',
      text: 'Vous allez recevoir un code de vérification par SMS.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      cancelButtonText: 'Annuler',
      confirmButtonText: 'Envoyer le code',
      showLoaderOnConfirm: true,
      preConfirm: (phoneNumber) => {
          this.loginWithPhone(phoneNumber)
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Validez le Capcha pour continuer',
        })
      }
    })
  }

  loginWithPhone(phoneNumber:any) {
   this.authService.loginWithPhone('+33' + phoneNumber, this.recaptchaVerifier) 
   .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      this.confirmationResult = confirmationResult;
      //console.log('ENVOI SMS', confirmationResult)


      Swal.fire({
        title: 'Entrez le code de vérification',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Se connecter',
        cancelButtonText: 'Annuler',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return this.confirmationResult.confirm(login).then((result:any) => {

            const user = result.user;
            this.analyseLogin(user)
            
            })
            .catch((error:any) => {
              this.errorLogin(error)
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            text: 'patientez quelques instants...',
          })
        }
      })


      // ...
    }).catch((error) => {
      // Error; SMS not sent
      this.errorLogin(error)
      // ...
    });
  }




  confirmCodeOTP() {
    Swal.fire({
        title: 'Entrez le code de vérification',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Se connecter',
        cancelButtonText: 'Annuler',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return this.confirmationResult.confirm(login).then((result:any) => {

            const user = result.user;
            this.analyseLogin(user)
            
            })
            .catch((error:any) => {
              this.errorLogin(error)
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            text: 'patientez quelques instants...',
          })
        }
      })
  }

  errorLogin(e:any) {
    localStorage.setItem('user', '');
    //console.log(e.message)

    Swal.fire({
      title: 'Oups !',
      text: e.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })

  }

  analyseLogin(datas:any) {
    //console.log(datas)
    //localStorage.setItem('user', JSON.stringify(datas));
    //check if user exist
    const user = this.authService.getUser()
    const uid = user.uid;
      
    this.authService.checkIfUserExist(uid)
    .then((user) => {
  
        Swal.fire({
          title: 'Connexion en cours...',
          html: 'Vous allez être redirigé vers votre compte',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(this.timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            //console.log('I was closed by the timer')

            if(user != null) {
              this.router.navigate(['/user-profile'])
            } else {
            this.router.navigate(['/vtsolution-project'])
            }

          }
        })


     
      // ...
    }).catch((error) => {
      // Error; SMS not sent
      this.router.navigate(['/vtsolution-project'])
      // ...
    });
    
  }


  register(configurateurData: LoginData) {
    this.authService
      .register(configurateurData)
      .then((user) => this.analyseLogin(user))
      .catch((e) => console.log('Register Fail')/*e.message*/);
  }


  askEmailForResetPassword() {
    Swal.fire({
      title: 'Entrez votre adresse mail',
      text: 'Vous allez recevoir un mail avec un lien pour réinitialiser votre mot de passe.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Envoyer le lien de réinitialisation',
      cancelButtonText: 'Annuler',
      showLoaderOnConfirm: true,
      preConfirm: (resetEmail) => {
          this.sendPasswordResetEmail(resetEmail)
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Un mail vient d\'être envoyé sur votre adresse pour réinitialiser votre mot de passe.',
        })
      }
    })
  }


  sendPasswordResetEmail(resetEmail) {
    this.authService
    .sendPasswordResetEmail(resetEmail);
  }


}

