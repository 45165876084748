import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { getAuth, RecaptchaVerifier } from "@angular/fire/auth";
import Swal from 'sweetalert2'
import { Router } from '@angular/router';
import { AuthService } from '../core/services/auth.service';
import { LoginData } from '../core/interfaces/login-data.interface';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
    selector: 'app-signup',
    templateUrl: './signup.component.html',
    styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

    @Output() formData: EventEmitter<{
        name: string;
        email: string;
        password: string;
        customCheckRegister:any; 
      }> = new EventEmitter();
    
      form: FormGroup;

    test : Date = new Date();
    focus;
    focus1;
    focus2;
   

    recaptchaVerifier:any
    confirmationResult:any
    timerInterval:any

    constructor(
        private readonly authService: AuthService,
        private readonly router: Router,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        const auth = getAuth();
      this.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
    'size': 'normal',
    'callback': (response:any) => {
      //console.log(response)
      // reCAPTCHA solved, allow signInWithPhoneNumber.
      // ...
    },
    'expired-callback': () => {
      // Response expired. Ask user to solve reCAPTCHA again.
      // ...
        }
      }, auth);

      this.form = this.fb.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        customCheckRegister: ['', Validators.required],
      });
    }
    


  signup() {

  if(this.form.valid) {

      if(this.form.value.customCheckRegister) {

      localStorage.setItem('displayName', this.form.value.name);
      this.authService
        .register(this.form.value)
        .then((datas) => this.analyseLogin(datas))
        .catch((e) => this.errorLogin(e));

      } else {
        Swal.fire({
          title: 'Oups !',
          text: 'Vous devez accepter les conditions d\'utilisation pour continuer...',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      }

    } else {
      Swal.fire({
        title: 'Oups !',
        text: 'Il manque quelques informations avant de continuer...',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }
  }


  loginWithGoogle() {
    this.authService
      .loginWithGoogle()
      .then((datas) => this.analyseLogin(datas))
      .catch((e) => this.errorLogin(e));
  }

  enterLoginPhone() {
    Swal.fire({
      title: 'Entrez votre numéro de téléphone',
      text: 'Vous allez recevoir un code de vérification par SMS.',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Envoyer le code',
      showLoaderOnConfirm: true,
      preConfirm: (phoneNumber) => {
          this.loginWithPhone(phoneNumber)
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          text: 'Merci de validez le Capcha pour continuer...',
        })
      }
    })
  }

  loginWithPhone(phoneNumber:any) {
   this.authService.loginWithPhone('+33' + phoneNumber, this.recaptchaVerifier) 
   .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      this.confirmationResult = confirmationResult;
      //console.log('ENVOI SMS', confirmationResult)


      Swal.fire({
        title: 'Entrez le code de vérification',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Se connecter',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return this.confirmationResult.confirm(login).then((result:any) => {

            const user = result.user;
            this.analyseLogin(user)
            
            })
            .catch((error:any) => {
              this.errorLogin(error)
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            text: 'patientez quelques instants...',
          })
        }
      })


      // ...
    }).catch((error) => {
      // Error; SMS not sent
      this.errorLogin(error)
      // ...
    });
  }




  confirmCodeOTP() {
    Swal.fire({
        title: 'Entrez le code de vérification',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Se connecter',
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          return this.confirmationResult.confirm(login).then((result:any) => {

            const user = result.user;
            this.analyseLogin(user)
            
            })
            .catch((error:any) => {
              this.errorLogin(error)
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            text: 'patientez quelques instants...',
          })
        }
      })
  }

  errorLogin(e:any) {
    localStorage.setItem('user', '');
    //console.log(e.message)

    Swal.fire({
      title: 'Oups !',
      text: e.message,
      icon: 'error',
      confirmButtonText: 'OK'
    })

  }

  analyseLogin(datas:any) {
    //console.log(datas)
      var infoUser = datas
      infoUser['uid'] = datas.id
    localStorage.setItem('user', JSON.stringify(infoUser));
    //check if user exist
    const user = this.authService.getUser()
    const uid = user.uid;
      
    this.authService.checkIfUserExist(uid)
    .then((user) => {
  
        Swal.fire({
          title: 'Connexion en cours...',
          html: 'Vous allez être redirigé vers votre compte',
          timer: 2000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(this.timerInterval)
          }
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
           // console.log('I was closed by the timer')

            if(user != null) {
              this.router.navigate(['/user-profile'])
            } else {
            this.router.navigate(['/user-profile'])
            }

          }
        })


     
      // ...
    }).catch((error) => {
      // Error; SMS not sent
      this.router.navigate(['/vtsolution-project'])
      // ...
    });
    
  }

/*
  register(configurateurData: LoginData) {
    this.authService
      .register(configurateurData)
      .then((user) => this.analyseLogin(user))
      .catch((e) => console.log(e.message));
  }*/



  showPrivacy() {
    Swal.fire({
      html: '<h1>Mentions légales</h1><p> Glissandco : Numéro de SIRET : 884 709 833 00013<br>61 rue Michelet 94700 Maisons-Alfort<br>Téléphone : 06 09 08 41 97<br>Email : contact@glissandco.fr<br>Site Web : www.glissandco.fr</p><br><a target="blank" href="http://localhost:4200/#/mentions-legales">Voir les mentions légales</a>',
    })
  }


}
